html {
  height: 100%;
}

body {
  min-height: 100%;
  margin: 0;
  padding: 0;
}

.recaptcha-div{
  margin-top:20px !important;
}
